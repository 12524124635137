/* TODO:
- Change language request link to a mailchimp form
- Scrape books from projects other than gutenberg, mix them into the classics list
- Make classics items clickable and have them open the book in the app
- Setup info@abal.ai email
*/
import { BrowserRouter, Routes, Route, Link, useLocation } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Pricing from './components/Pricing';
import Mission from './components/Mission';
import React, { useState, useEffect, useMemo } from 'react';
// import Research from './components/Research';
import Work from './components/Work';
import CookieNotice from './components/CookieNotice';
import { apiRequest } from './config/api.js';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './i18n';
import LanguageRequestModal from './components/LanguageRequestModal';

import TranslationBlocker from './TranslationBlocker';
import { initGA, logPageView } from './analytics';

// Add an array of RTL languages
const RTL_LANGUAGES = ['ar', 'fa', 'he', 'ur']; // Arabic, Persian, Hebrew, Urdu

const App = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showCookieNotice, setShowCookieNotice] = useState(false);
  const [showCookiePreferences, setShowCookiePreferences] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    analytics: false,
  });
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [languageOptions, setLanguageOptions] = useState(null);
  const [isLanguageOptionsLoading, setIsLanguageOptionsLoading] = useState(true);
  const [marketingContent, setMarketingContent] = useState({
    nativeContent: '',
    learningContent: {},
    associations: {},
    pronunciation: {},
    furigana: {}
  });

  const [languageId, setLanguageId] = useState(() => {
    // Get language from URL if present
    const params = new URLSearchParams(window.location.search);
    const urlLang = params.get('n');
    
    // Priority: URL param > localStorage > default 'en'
    return urlLang || localStorage.getItem('preferredLanguage') || 'en';
  });

  const appSubdomain = process.env.REACT_APP_APP_SUBDOMAIN;
  const appSchema = process.env.REACT_APP_APP_SCHEMA || 'https';

  console.log(`appSubdomain: ${appSubdomain}`);
  console.log(`appSchema: ${appSchema}`);

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const fetchLanguages = async () => {
    try {
      setIsLanguageOptionsLoading(true);
      const response = await apiRequest('GET', 'LANGUAGE_OPTIONS');
      setLanguageOptions(response);
    } catch (error) {
      console.error('Error fetching language options:', error);
    } finally {
      setIsLanguageOptionsLoading(false);
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    document.title = 'All Books, All Languages ';
  }, []);

  useEffect(() => {
    const preferences = localStorage.getItem('cookiePreferences');
    if (preferences) {
      const parsedPreferences = JSON.parse(preferences);
      setShowCookieNotice(false);
      setCookiePreferences(parsedPreferences);
      initAnalytics(parsedPreferences);
    } else {
      setShowCookieNotice(true);
    }
  }, []);

  const handleCookiePreferencesChange = (preferences) => {
    setCookiePreferences(preferences);
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    setShowCookieNotice(false);
    setShowCookiePreferences(false);
    initAnalytics(preferences);
  };

  const handleCookieNoticeClose = () => {
    setShowCookieNotice(false);
  };

  const openCookiePreferences = () => {
    setShowCookiePreferences(true);
  };

  // Add handlers for navigation and modal
  const handleTryApp = () => {
    window.open(`${appSchema}://${appSubdomain}/`, '_blank');
  };

  const handleWatchDemo = () => {
    setShowVideo(!showVideo);
    if (!showVideo) {
      document.querySelector('.video-container')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLogoClick = () => {
    window.location.href = '/';
  };

  const handleRequestLanguage = () => {
    setShowLanguageModal(true);
  };

  const handleCloseLanguageModal = () => {
    setShowLanguageModal(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Add new function to organize classics into columns
  const renderClassicBooks = () => {
    const classics = [
      { title: "Frankenstein", author: "Mary Wollstonecraft Shelley", uuid: "d12a95e3-0414-55d2-998b-95a1ff94c475" },
      { title: "Pride and Prejudice", author: "Jane Austen", uuid: "3e950b11-229d-5ffb-a7b6-b8e5a539451a" },
      { title: "Romeo and Juliet", author: "William Shakespeare", uuid: "50fd8286-8b97-5769-a1b6-15a5152f6744" },
      { title: "Moby Dick", author: "Herman Melville", uuid: "963fa88d-6367-5d84-a1fb-3424bda94498" },
      { title: "The Scarlet Letter", author: "Nathaniel Hawthorne", uuid: "71d403f8-c92b-5cd3-833f-99ab91a6b0ab" },
      { title: "Alice in Wonderland", author: "Lewis Carroll", uuid: "1d908261-9627-5ab9-88be-db7de1f8db94" },
      { title: "Middlemarch", author: "George Eliot", uuid: "268114e3-b72e-571d-b8d8-919a0c753464" },
      { title: "A Room with a View", author: "E. M. Forster", uuid: "4f79f9a5-7620-5efa-83de-895f8ac1b8ba" },
      { title: "Little Women", author: "Louisa May Alcott", uuid: "9929ad23-b431-5c66-9aa4-79fed6b73eb0" },
      { title: "The Blue Castle", author: "L. M. Montgomery", uuid: "f80a9df9-8538-5c1e-861f-fd98cf2927c1" },
      { title: "The Enchanted April", author: "Elizabeth Von Arnim", uuid: "9be2f985-9cfc-55b1-8f76-6a935f36504b" },
      { title: "Sleepy Hollow", author: "Washington Irving", uuid: "2ec3667d-5c9d-5050-a8a6-e9ca6a8e0245" },
      { title: "A Doll's House", author: "Henrik Ibsen", uuid: "f6865c22-72e7-5bb3-b5d9-5590394290bf" },
      { title: "Cranford", author: "Elizabeth Cleghorn Gaskell", uuid: "7bac1800-9656-5b06-a02a-2df3f3b562c6" },
      { title: "Tom Jones", author: "Henry Fielding", uuid: "deac199c-4a72-54a7-b908-329606c286df" },
      { title: "Dracula", author: "Bram Stoker", uuid: "aa2727d9-41cb-5f08-a1d1-02d2b478f6be" },
      { title: "Dorian Gray", author: "Oscar Wilde", uuid: "ab84c81f-1811-5109-a588-7cad10acbc19" },
      { title: "The Great Gatsby", author: "F. Scott Fitzgerald", uuid: "c292a816-cb9c-59c7-afda-c7ada5cd28d0" },
      { title: "Metamorphosis", author: "Franz Kafka", uuid: "a6779503-f6ac-542b-baac-f628f456b4cf" },
      { title: "呻吟語", author: "Kun Lü", uuid: "1c1eb6e1-0265-5f2a-a5fe-2ded7a5dfa57" }
    ];

    return (
      <div className="classics-container">
        {classics.map((book, idx) => (
          <div key={idx} className="classic-item">
            <a 
              href={`${appSchema}://${appSubdomain}/?n=${languageId}&b=${book.uuid}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <div className="classic-title">{book.title}</div>
              <div className="classic-author">{book.author}</div>
            </a>
          </div>
        ))}
      </div>
    );
  };

  // Add new function to organize languages into columns
  const renderLanguageColumns = () => {
    const languageData = {
      "العربية": { name: "Arabic", wiki: "https://en.wikipedia.org/wiki/Arabic_language" },
      "বাংলা": { name: "Bengali", wiki: "https://en.wikipedia.org/wiki/Bengali_language" },
      "Deutsch": { name: "German", wiki: "https://en.wikipedia.org/wiki/German_language" },
      "Ελληνικά": { name: "Greek", wiki: "https://en.wikipedia.org/wiki/Greek_language" },
      "English": { name: "English", wiki: "https://en.wikipedia.org/wiki/English_language" },
      "Español": { name: "Spanish", wiki: "https://en.wikipedia.org/wiki/Spanish_language" },
      "فارسی": { name: "Persian", wiki: "https://en.wikipedia.org/wiki/Persian_language" },
      "Français": { name: "French", wiki: "https://en.wikipedia.org/wiki/French_language" },
      "ગુજરાતી": { name: "Gujarati", wiki: "https://en.wikipedia.org/wiki/Gujarati_language" },
      "עברית": { name: "Hebrew", wiki: "https://en.wikipedia.org/wiki/Hebrew_language" },
      "हिन्दी": { name: "Hindi", wiki: "https://en.wikipedia.org/wiki/Hindi_language" },
      "Bahasa Indonesia": { name: "Indonesian", wiki: "https://en.wikipedia.org/wiki/Indonesian_language" },
      "Italiano": { name: "Italian", wiki: "https://en.wikipedia.org/wiki/Italian_language" },
      "日本語": { name: "Japanese", wiki: "https://en.wikipedia.org/wiki/Japanese_language" },
      "한국어": { name: "Korean", wiki: "https://en.wikipedia.org/wiki/Korean_language" },
      "Latin": { name: "Latin", wiki: "https://en.wikipedia.org/wiki/Latin" },
      "മലയാളം": { name: "Malayalam", wiki: "https://en.wikipedia.org/wiki/Malayalam_language" },
      "मराठी": { name: "Marathi", wiki: "https://en.wikipedia.org/wiki/Marathi_language" },
      "Bahasa Melayu": { name: "Malay", wiki: "https://en.wikipedia.org/wiki/Malay_language" },
      "Nederlands": { name: "Dutch", wiki: "https://en.wikipedia.org/wiki/Dutch_language" },
      "Polski": { name: "Polish", wiki: "https://en.wikipedia.org/wiki/Polish_language" },
      "Português": { name: "Portuguese", wiki: "https://en.wikipedia.org/wiki/Portuguese_language" },
      "Русский": { name: "Russian", wiki: "https://en.wikipedia.org/wiki/Russian_language" },
      "Svenska": { name: "Swedish", wiki: "https://en.wikipedia.org/wiki/Swedish_language" },
      "தமிழ்": { name: "Tamil", wiki: "https://en.wikipedia.org/wiki/Tamil_language" },
      "తెలుగు": { name: "Telugu", wiki: "https://en.wikipedia.org/wiki/Telugu_language" },
      "ไทย": { name: "Thai", wiki: "https://en.wikipedia.org/wiki/Thai_language" },
      "tlhIngan Hol": { name: "Klingon", wiki: "https://en.wikipedia.org/wiki/Klingon_language" },
      "Türkçe": { name: "Turkish", wiki: "https://en.wikipedia.org/wiki/Turkish_language" },
      "اردو": { name: "Urdu", wiki: "https://en.wikipedia.org/wiki/Urdu_language" },
      "Українська": { name: "Ukrainian", wiki: "https://en.wikipedia.org/wiki/Ukrainian_language" },
      "Tiếng Việt": { name: "Vietnamese", wiki: "https://en.wikipedia.org/wiki/Vietnamese_language" },
      "中文": { name: "Chinese", wiki: "https://en.wikipedia.org/wiki/Chinese_language" },
      "繁體中文": { name: "Chinese (Traditional)", wiki: "https://en.wikipedia.org/wiki/Traditional_Chinese_characters" }
    };

    const languages = Object.keys(languageData);

    return (
      <div className="language-container">
        {languages.map((lang, idx) => (
          <div 
            key={idx} 
            className="language-item" 
            title={languageData[lang].name}
            data-wiki={languageData[lang].wiki}
            style={{ cursor: 'default' }}
          >
            {lang}
          </div>
        ))}
      </div>
    );
  };
    
  // Add this new component near the top of the file
  const BubblingBackground = () => {
    useEffect(() => {
      // Array of characters from different languages
      const characters = [
        // Latin
        "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",

        // Japanese (Hiragana & Katakana)
        "あ", "い", "う", "え", "お", "か", "き", "く", "け", "こ", "ア", "イ", "ウ", "エ", "オ", "カ", "キ", "ク", "ケ", "コ",
        
        // Chinese (Simplified & Traditional)
        "愛", "心", "水", "火", "風", "天", "地", "山", "川", "月", "日", "星", "雨", "雪", "語", "字", "書", "讀", "說", "看",
        
        // Korean (Hangul)
        "ㄱ", "ㄴ", "ㄷ", "ㄹ", "ㅁ", "ㅂ", "ㅅ", "ㅇ", "ㅈ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ", "한", "글", "말", "책", "읽", "다",
        
        // Arabic
        "ا", "ب", "ت", "ث", "ج", "ح", "خ", "د", "ذ", "ر", "ز", "س", "ش", "ص", "ض", "ط", "ظ", "ع", "غ", "ف",
        
        // Russian (Cyrillic)
        "а", "б", "в", "г", "д", "е", "ж", "з", "и", "й", "к", "л", "м", "н", "о", "п", "р", "с", "т", "у",
        
        // Greek
        "α", "β", "γ", "δ", "ε", "ζ", "η", "θ", "ι", "κ", "λ", "μ", "ν", "ξ", "ο", "π", "ρ", "σ", "τ", "υ",
        
        // Hindi & Sanskrit (Devanagari)
        "अ", "आ", "इ", "ई", "उ", "ऊ", "ए", "ऐ", "ओ", "औ", "क", "ख", "ग", "घ", "ङ", "च", "छ", "ज", "झ", "ञ",
        
        // Thai
        "ก", "ข", "ฃ", "ค", "ฅ", "ฆ", "ง", "จ", "ฉ", "ช", "ซ", "ฌ", "ญ", "ฎ", "ฏ", "ฐ", "ฑ", "ฒ", "ณ", "ด",
        
        // Hebrew
        "א", "ב", "ג", "ד", "ה", "ו", "ז", "ח", "ט", "י", "כ", "ל", "מ", "נ", "ס", "ע", "פ", "צ", "ק", "ר",
        
        // Persian
        "آ", "ب", "پ", "ت", "ث", "ج", "چ", "ح", "خ", "د", "ذ", "ر", "ز", "ژ", "س", "ش", "ص", "ض", "ط", "ظ",
        
        // Bengali
        "অ", "আ", "ই", "ঈ", "উ", "ঊ", "ঋ", "এ", "ঐ", "ও", "ঔ", "ক", "খ", "গ", "ঘ", "ঙ", "চ", "ছ", "জ", "ঝ",
        
        // European Special Characters
        "é", "è", "ê", "ë", "ñ", "ü", "ö", "ä", "ß", "ø", "å", "æ", "ç", "ł", "ń", "ś", "ź", "ż", "ř", "ů",
        
        // Tamil
        "அ", "ஆ", "இ", "ஈ", "உ", "ஊ", "எ", "ஏ", "ஐ", "ஒ", "ஓ", "ஔ", "க", "ங", "ச", "ஞ", "ட", "ண", "த", "ந",
        
        // Georgian
        "ა", "ბ", "გ", "დ", "ე", "ვ", "ზ", "თ", "ი", "კ", "ლ", "მ", "ნ", "ო", "პ", "ჟ", "რ", "ს", "ტ", "უ"
      ];

      const createCharacter = () => {
        const char = document.createElement('div');

        char.className = 'floating-char ' + 
          (Math.random() < 0.5 ? 'rotate-clockwise' : 'rotate-counterclockwise');
        char.textContent = characters[Math.floor(Math.random() * characters.length)];
        
        char.style.left = `${5 + Math.random() * 90}%`;
        char.style.top = `${80 + Math.random() * 20}%`;
        
        const size = 14 + Math.random() * 40;
        char.style.fontSize = `${size}px`;
      
        char.style.animationDuration = `${10 + Math.random() * 3}s`;
        
        document.querySelector('.bubbling-background').appendChild(char);
        
        char.addEventListener('animationend', () => char.remove());
      };

      const interval = setInterval(createCharacter, 500);
      return () => clearInterval(interval);
    }, []);

    return <div className="bubbling-background" />;
  };

  // Effect to handle URL changes
  useEffect(() => {
    const handleUrlChange = () => {
      const params = new URLSearchParams(window.location.search);
      const urlLang = params.get('n');
      if (urlLang && urlLang !== languageId) {
        setLanguageId(urlLang);
      }
    };

    // Listen for URL changes
    window.addEventListener('popstate', handleUrlChange);
    return () => window.removeEventListener('popstate', handleUrlChange);
  }, [languageId]);

  // Effect to sync languageId with i18n and localStorage
  useEffect(() => {
    // Store language preference
    localStorage.setItem('preferredLanguage', languageId);
    // Update i18n language
    i18n.changeLanguage(languageId);
    // Update URL if needed
    const params = new URLSearchParams(window.location.search);
    const urlLang = params.get('n');
    if (!urlLang || urlLang !== languageId) {
      params.set('n', languageId);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.pushState({}, '', newUrl);
    }
  }, [languageId]);

  // Update language handler
  const handleLanguageChange = (newLanguage) => {
    setLanguageId(newLanguage);
  };

  const initAnalytics = (preferences) => {
    if (preferences.analytics) {
      initGA();
      logPageView();
    }
  };

  // Add a function to determine text direction
  const getTextDirection = (langId) => {
    return RTL_LANGUAGES.includes(langId) ? 'rtl' : 'ltr';
  };

  const [demoContent, setDemoContent] = useState(null);
  const [demoPronunciation, setDemoPronunciation] = useState(null);
  const [demoFurigana, setDemoFurigana] = useState(null);

  useEffect(() => {
    const fetchDemoContent = async () => {
      const DEMO_BOOK_ID = 'b05172dd-b26f-50e1-88a7-fca1f0eb5928';
      const LEARNING_LANGUAGE = 'zh';
      
      try {
        // Get first page ID
        const firstPageResponse = await apiRequest('GET', 'BOOK_FIRST_PAGE', { book_id: DEMO_BOOK_ID });
        const pageId = firstPageResponse.page_id;
        
        // Base requests array
        const requests = [
          apiRequest('GET', 'BOOK_PAGE', { book_id: DEMO_BOOK_ID, page_id: pageId }, { native_language: languageId }),
          apiRequest('GET', 'BOOK_PAGE', { book_id: DEMO_BOOK_ID, page_id: pageId }, { learning_language: LEARNING_LANGUAGE }),
          apiRequest('GET', 'BOOK_PAGE_ASSOCIATIONS', { book_id: DEMO_BOOK_ID, page_id: pageId }, { native_language: languageId, learning_language: LEARNING_LANGUAGE }),
          apiRequest('GET', 'BOOK_PAGE_PRONUNCIATION', { book_id: DEMO_BOOK_ID, page_id: pageId }, { native_language: languageId, learning_language: LEARNING_LANGUAGE }),
        ];

        // Only add furigana request if learning Japanese
        if (LEARNING_LANGUAGE === 'ja') {
          requests.push(apiRequest('GET', 'FURIGANA_TRANSCRIPTION', { book_id: DEMO_BOOK_ID, page_id: pageId }, { learning_language: LEARNING_LANGUAGE }));
        }

        // Fetch all required data in parallel
        const responses = await Promise.all(requests);
        const [nativeContent, learningContent, associations, pronunciation, ...rest] = responses;

        // Combine the data
        setDemoContent({
          native_content: nativeContent.content,
          learning_content: learningContent.content,
          association: associations,
          native_language: languageId,
          learning_language: LEARNING_LANGUAGE,
          page_number: nativeContent.page_number
        });
        setDemoPronunciation(pronunciation);
        setDemoFurigana(LEARNING_LANGUAGE === 'ja' ? rest[0] : null);
      } catch (error) {
        console.error('Error fetching demo content:', error);
      }
    };

    fetchDemoContent();
  }, [languageId]); // Changed dependency from nativeLanguage to languageId

  // Add new state for feature card contents
  const [featureCardContents, setFeatureCardContents] = useState({
    wordAssociations: null,
    furigana: null,
    phonetics: null,
    hardMode: null,
    dyslexic: null
  });
  const [featureCardsLoading, setFeatureCardsLoading] = useState(true);

  // Update the selectRandomLanguages function
  const selectRandomLanguages = (excludeLanguage) => {
    const DYSLEXIC_SUPPORTED_LANGUAGES = ['de', 'el', 'en', 'es', 'fr', 'id', 'it', 'la', 'ms', 'nl', 'pl', 'pt', 'sv', 'ru', 'tlh', 'ua', 'tr', 'vi'];
    
    const languagePreferences = {
      wordAssociations: 'zh', // Chinese for word associations
      furigana: 'ja',         // Japanese for furigana
      phonetics: ['ar', 'ru', 'ko', 'hi'].filter(lang => lang !== excludeLanguage)[0], // Languages with interesting phonetics
      hardMode: ['fr', 'es', 'de', 'it'].filter(lang => lang !== excludeLanguage)[0], // Common languages for hard mode
      // Use native language if supported, otherwise pick a random supported language
      dyslexic: DYSLEXIC_SUPPORTED_LANGUAGES.includes(excludeLanguage) 
        ? excludeLanguage 
        : DYSLEXIC_SUPPORTED_LANGUAGES[Math.floor(Math.random() * DYSLEXIC_SUPPORTED_LANGUAGES.length)]
    };

    return languagePreferences;
  };

  // Modify the fetch demo content function
  const fetchFeatureCardContent = async () => {
    const DEMO_BOOK_ID = 'b05172dd-b26f-50e1-88a7-fca1f0eb5928';
    setFeatureCardsLoading(true);
    
    try {
      // Get first page ID
      const firstPageResponse = await apiRequest('GET', 'BOOK_FIRST_PAGE', { book_id: DEMO_BOOK_ID });
      const pageId = firstPageResponse.page_id;
      
      // Get language preferences
      const languages = selectRandomLanguages(languageId);
      
      // Function to fetch and update a single feature card
      const fetchFeatureCard = async (featureKey, learningLang) => {
        try {
          // For dyslexic feature, use the same language for both native and learning
          const nativeLang = featureKey === 'dyslexic' ? learningLang : languageId;
          
          // Stage 1: Fetch base content
          const [nativeContent, learningContent] = await Promise.all([
            apiRequest('GET', 'BOOK_PAGE', 
              { book_id: DEMO_BOOK_ID, page_id: pageId }, 
              { native_language: nativeLang }
            ),
            apiRequest('GET', 'BOOK_PAGE', 
              { book_id: DEMO_BOOK_ID, page_id: pageId }, 
              { learning_language: learningLang }
            )
          ]);

          // Update state with base content
          setFeatureCardContents(prev => ({
            ...prev,
            [featureKey]: {
              native_content: nativeContent.content,
              learning_content: learningContent.content,
              native_language: nativeLang,
              learning_language: learningLang,
              page_number: nativeContent.page_number
            }
          }));

          // Stage 2: Fetch additional content based on feature type
          let additionalContent;
          switch (featureKey) {
            case 'wordAssociations':
            case 'hardMode':
              additionalContent = await apiRequest('GET', 'BOOK_PAGE_ASSOCIATIONS',
                { book_id: DEMO_BOOK_ID, page_id: pageId },
                { native_language: nativeLang, learning_language: learningLang }
              );
              setFeatureCardContents(prev => ({
                ...prev,
                [featureKey]: {
                  ...prev[featureKey],
                  association: additionalContent
                }
              }));
              break;

            case 'phonetics':
              additionalContent = await apiRequest('GET', 'BOOK_PAGE_PRONUNCIATION',
                { book_id: DEMO_BOOK_ID, page_id: pageId },
                { native_language: nativeLang, learning_language: learningLang }
              );
              setFeatureCardContents(prev => ({
                ...prev,
                [featureKey]: {
                  ...prev[featureKey],
                  pronunciation: additionalContent
                }
              }));
              break;

            case 'furigana':
              if (learningLang === 'ja') {
                additionalContent = await apiRequest('GET', 'FURIGANA_TRANSCRIPTION',
                  { book_id: DEMO_BOOK_ID, page_id: pageId },
                  { learning_language: learningLang }
                );
                setFeatureCardContents(prev => ({
                  ...prev,
                  [featureKey]: {
                    ...prev[featureKey],
                    furigana: additionalContent
                  }
                }));
              }
              break;

            case 'dyslexic':
              // Dyslexic feature only needs base content
              break;
          }
        } catch (error) {
          console.error(`Error fetching ${featureKey} feature card:`, error);
          // Set error state for this specific feature card if needed
        }
      };

      // Start fetching all feature cards independently
      const featureRequests = [
        fetchFeatureCard('wordAssociations', languages.wordAssociations),
        fetchFeatureCard('furigana', languages.furigana),
        fetchFeatureCard('phonetics', languages.phonetics),
        fetchFeatureCard('hardMode', languages.hardMode),
        fetchFeatureCard('dyslexic', languages.dyslexic)
      ];

      // Wait for all features to complete (but they'll update UI incrementally)
      await Promise.all(featureRequests);

    } catch (error) {
      console.error('Error in feature card initialization:', error);
    } finally {
      setFeatureCardsLoading(false);
    }
  };

  // Replace the existing useEffect for demo content
  useEffect(() => {
    fetchFeatureCardContent();
  }, [languageId]);

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <div className="app" dir={languageOptions?.[languageId]?.direction || 'ltr'}>
          <BubblingBackground />
          {/* Black Navigation Bar */}
          <div className="nav-container">
            <nav className="navbar">
              {/* Logo Section */}
              <div className="nav-logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                <div className="logo-box">
                  <img src="apple-touch-icon.png" className="logo-inner" alt="ABAL Logo" />
                </div>
                <span>ABAL</span>
              </div>

              {/* Hamburger Menu Button */}
              <button className="hamburger" onClick={toggleMenu}>
                ☰
              </button>

              {/* Navigation Links */}
              <div className={`nav-links ${isMenuOpen ? 'show' : ''}`}>
                <Link to="/"><button onClick={() => setIsMenuOpen(false)}>{t('nav.home')}</button></Link>
                <Link to="/pricing"><button onClick={() => setIsMenuOpen(false)}>{t('nav.pricing')}</button></Link>                
                <Link to="/mission"><button onClick={() => setIsMenuOpen(false)}>{t('nav.mission')}</button></Link>
                <Link to="/work"><button onClick={() => setIsMenuOpen(false)}>{t('nav.work')}</button></Link>
                {/* <Link to="/research"><button onClick={() => setIsMenuOpen(false)}>{t('nav.research')}</button></Link> */}
              </div>
              
              {/* Actions */}
              <div className="nav-actions">
                <button className="try-app" onClick={handleTryApp}>{t('nav.openApp')}</button>
              </div>
            </nav>
          </div>

          {(showCookieNotice || showCookiePreferences) && (
            <CookieNotice
              show={showCookieNotice || showCookiePreferences}
              onClose={handleCookieNoticeClose}
              onSavePreferences={handleCookiePreferencesChange}
              initialPreferences={cookiePreferences}
              showCustomize={showCookiePreferences}
            />
          )}

          <LanguageRequestModal 
            isOpen={showLanguageModal}
            onClose={handleCloseLanguageModal}
          />

          <TranslationBlocker languageId={languageId} />

          <Routes>
            <Route path="/" element={<Home 
              showVideo={showVideo}
              handleWatchDemo={handleWatchDemo}
              handleTryApp={handleTryApp}
              handleRequestLanguage={handleRequestLanguage}
              renderLanguageColumns={renderLanguageColumns}
              renderClassicBooks={renderClassicBooks}
              demoContent={demoContent}
              demoPronunciation={demoPronunciation}
              demoFurigana={demoFurigana}
              featureCardContents={featureCardContents}
              featureCardsLoading={featureCardsLoading}
            />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/work" element={<Work />} />
            <Route path="/mission" element={<Mission />} />
            {/* <Route path="/research" element={<Research />} /> */}
          </Routes>

          {/* Footer */}
          <footer className="footer">
            <div className="footer-content">
              {/* Logo and Email */}
              <div className="footer-left">
                <div className="footer-logo">
                  <span>All Books, All Languages</span>
                </div>
                <div className="footer-contact">
                <a href="mailto:info@abal.ai" className="footer-email">info@abal.ai</a>
                  {!isLanguageOptionsLoading && languageOptions && (
                    <select 
                      value={languageId}
                      onChange={(e) => handleLanguageChange(e.target.value)}
                      className="language-selector"
                      aria-label={t('footer.selectLanguage')}
                    >
                      {Object.entries(languageOptions).map(([code, langData]) => (
                        <option key={code} value={code}>
                          {langData.native_name || langData.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>

              {/* Footer Links */}
              <div className="footer-links">

              <div className="footer-column">
                  <a href="/mission">{t('footer.mission')}</a>
                  <a href="/work">{t('footer.work')}</a>
                  {/* <a href="/research">{t('footer.research')}</a> */}
                </div>

                <div className="footer-column">
                  <a href="https://www.voxos.ai">{t('footer.company')}</a>
                  <a href="#" onClick={(e) => { e.preventDefault(); openCookiePreferences(); }}>{t('footer.cookies')}</a>
                </div>

                <div className="footer-column">
                  <a href={`${appSchema}://${appSubdomain}/privacy-policy`}>{t('footer.privacy')}</a>
                  <a href={`${appSchema}://${appSubdomain}/terms-of-service`}>{t('footer.terms')}</a>
                </div>
              </div>
            </div>  

            {/* Footer Bottom */}
            <div className="footer-bottom">
              <span>{t('footer.createdBy')} voxos.ai</span>
              <span className="copyright">{t('footer.copyright', { year: '2024' })}</span>
            </div>
          </footer>
        </div>
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default App;