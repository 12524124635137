import React, { useState } from 'react';
import './css/Work.css';
import { useTranslation } from 'react-i18next';
import AuditApplicationModal from './AuditApplicationModal';
import PublishApplicationModal from './PublishApplicationModal';
const Work = () => {
    const { t } = useTranslation();
    const [isAuditApplicationModalOpen, setIsAuditApplicationModalOpen] = useState(false);
    const [isPublishApplicationModalOpen, setIsPublishApplicationModalOpen] = useState(false);
    return (
      <main className="main-content">
        <h1>{t('work.title')}</h1>
        <p className="subtitle">
            {t('work.subtitle')}
        </p>

        <div className="work-container">
          <div className="work-card">
            <div className="work-left">
              <h3>{t('work.publish.title')}</h3>
              <p>{t('work.publish.description')}</p>
            </div>
            <div className="work-right">
              <button className="work-buttons" onClick={() => setIsPublishApplicationModalOpen(true)}>{t('work.publish.cta')}</button>
            </div>
          </div>
          
          <div className="work-card">
            <div className="work-left">
              <h3>{t('work.audit.title')}</h3>
              <p>{t('work.audit.description')}</p>
            </div>
            <div className="work-right">
              <button className="work-buttons" onClick={() => setIsAuditApplicationModalOpen(true)}>{t('work.audit.cta')}</button>
            </div>
          </div>
        </div>

        <AuditApplicationModal 
          isOpen={isAuditApplicationModalOpen}
          onClose={() => setIsAuditApplicationModalOpen(false)}
        />
        <PublishApplicationModal 
          isOpen={isPublishApplicationModalOpen}
          onClose={() => setIsPublishApplicationModalOpen(false)}
        />
      </main>
    );
  };
  
  export default Work;