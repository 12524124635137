import './css/Mission.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdatesSubscriptionModal from './UpdatesSubscriptionModal';

const Mission = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleJoin = () => {
        setIsModalOpen(true);
    };

    return (
      <main className="main-content">
        <h1>{t('mission.title')}</h1>
        <p className="subtitle">
            {t('mission.subtitle')}
        </p>
        <div className="cta-buttons">
          <button className="cta-button" onClick={handleJoin}>
            <span>{t('mission.cta')}</span>
          </button>
        </div>
        <div className="mission-content">
            <p>{t('mission.content.paragraph1')}</p>
            <p>{t('mission.content.paragraph2')}</p>
            <p>{t('mission.content.paragraph3')}</p>
        </div>
        <UpdatesSubscriptionModal 
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        />
      </main>
    );
};

export default Mission;