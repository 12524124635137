import axios from 'axios';

const getApiBaseUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === 'prd' || process.env.REACT_APP_NODE_ENV === 'stg') {
    return `https://${process.env.REACT_APP_API_SUBDOMAIN}`;
  } else {
    return 'http://localhost:5000';
  }
};

export const API_BASE_URL = getApiBaseUrl();

export const API_ENDPOINTS = {
  REQUEST_LANGUAGE: '/api/languages/request',
  AUDIT_APPLICATION: '/api/forms/audit',
  PUBLISH_APPLICATION: '/api/forms/publish',
  LANGUAGE_OPTIONS: '/api/languages/options',
  SUBSCRIBE_UPDATES: '/api/forms/subscribe-updates',
  BOOK_FIRST_PAGE: '/api/books/:book_id/first_page',  
  BOOK_PAGE: '/api/books/:book_id/page/:page_id',
  BOOK_PAGE_ASSOCIATIONS: '/api/books/:book_id/page/:page_id/associations',
  BOOK_PAGE_PRONUNCIATION: '/api/books/:book_id/page/:page_id/phonetic_transcription',
  FURIGANA_TRANSCRIPTION: '/api/books/:book_id/page/:page_id/furigana_transcription',
};

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

export const setUserLocaleHeader = (locale) => {
  axiosInstance.defaults.headers.common['X-User-Locale'] = locale;
};

export const getApiUrl = (endpoint, pathParams = {}, queryParams = {}) => {
  let url = `${API_BASE_URL}${API_ENDPOINTS[endpoint]}`;
  
  // Replace path parameters
  Object.keys(pathParams).forEach(key => {
    url = url.replace(`:${key}`, encodeURIComponent(pathParams[key]));
  });

  // Add query parameters
  const queryString = new URLSearchParams(queryParams).toString();
  if (queryString) {
    url += `?${queryString}`;
  }

  return url;
};

export const apiRequest = async (method, endpoint, pathParams = {}, queryParams = {}, data = null, signal = null) => {
  const url = getApiUrl(endpoint, pathParams, queryParams);
  try {
    const response = await axiosInstance({
      method,
      url,
      data,
      signal,
    });
    return response.data;
  } catch (error) {
    console.error(`API request error (${method} ${url}):`, error);
    throw error;
  }
};