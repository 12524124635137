import React, { useState } from 'react';
import { apiRequest } from '../config/api.js';
import { useTranslation } from 'react-i18next';
import './css/Modal.css';

const UpdatesSubscriptionModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await apiRequest('POST', 'SUBSCRIBE_UPDATES', {}, {}, {
        email: email,
      });
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error subscribing to updates:', error);
      setSubmissionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setSubmissionStatus(null);
    setEmail('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {submissionStatus === null ? (
          <>
            <h2>{t('updatesSubscription.title')}</h2>
            <p>{t('updatesSubscription.description')}</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">{t('updatesSubscription.email')}</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="modal-actions">
                <button type="button" onClick={handleClose} className="cancel-button">
                  {t('common.cancel')}
                </button>
                <button type="submit" disabled={isSubmitting} className="submit-button">
                  {isSubmitting ? t('common.submitting') : t('common.submit')}
                </button>
              </div>
            </form>
          </>
        ) : submissionStatus === 'success' ? (
          <>
            <p>{t('updatesSubscription.successMessage')}</p>
            <div className="modal-actions">
              <button onClick={handleClose} className="close-button">
                {t('common.close')}
              </button>
            </div>
          </>
        ) : (
          <>
            <p>{t('updatesSubscription.errorMessage')}</p>
            <div className="modal-actions">
              <button onClick={handleClose} className="close-button">
                {t('common.close')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UpdatesSubscriptionModal;