import React, { useState, useMemo} from 'react';
import { apiRequest } from '../config/api.js';
import './css/LanguageRequestModal.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import './css/AuditApplicationModal.css';
import './css/Modal.css';
import LanguageSelector from './LanguageSelector';

const AuditApplicationModal = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    fluentLanguages: [],
    personalNote: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isShaking, setIsShaking] = useState(false);

  // Reuse the languages array from LanguageRequestModal
  const languages = useMemo(() => {
    return [
      { iso: 'ar' },
      { iso: 'bn' },
      { iso: 'de' },
      { iso: 'el' },
      { iso: 'en' },
      { iso: 'es' },
      { iso: 'fa' },
      { iso: 'fr' },
      { iso: 'gu' },
      { iso: 'he' },
      { iso: 'hi' },
      { iso: 'id' },
      { iso: 'it' },
      { iso: 'ja' },
      { iso: 'ko' },
      { iso: 'la' },
      { iso: 'ml' },
      { iso: 'mr' },
      { iso: 'ms' },
      { iso: 'nl' },
      { iso: 'pl' },
      { iso: 'pt' },
      { iso: 'ru' },
      { iso: 'sv' },
      { iso: 'ta' },
      { iso: 'te' },
      { iso: 'th' },
      { iso: 'tlh' },
      { iso: 'tr' },
      { iso: 'ur' },
      { iso: 'ua' },
      { iso: 'vi' },
      { iso: 'zh' },
      { iso: 'zh-TW' }
    ].sort((a, b) => {
      const nameA = t(`languages.${a.iso}`);
      const nameB = t(`languages.${b.iso}`);
      return nameA.localeCompare(nameB, i18n.language);
    });
  }, [t, i18n.language]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddLanguage = (langIso) => {
    setFormData(prev => ({
      ...prev,
      fluentLanguages: [...new Set([...prev.fluentLanguages, langIso])]
    }));
  };

  const handleRemoveLanguage = (langIso) => {
    setFormData(prev => ({
      ...prev,
      fluentLanguages: prev.fluentLanguages.filter(iso => iso !== langIso)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.fluentLanguages.length < 2) {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 820);
      return;
    }
    
    setIsSubmitting(true);
    try {
      const response = await apiRequest('POST', 'AUDIT_APPLICATION', {}, {}, {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        fluent_languages: formData.fluentLanguages,
        personal_note: formData.personalNote,
        user_locale_code: i18n.language
      });
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error submitting audit application:', error);
      setSubmissionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setSubmissionStatus(null);
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      fluentLanguages: [],
      personalNote: ''
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {submissionStatus === null ? (
          <>
            <h2>{t('auditApplication.title')}</h2>
            <p>{t('auditApplication.description')}</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="firstName">{t('auditApplication.firstName')}</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">{t('auditApplication.lastName')}</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">{t('auditApplication.email')}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>{t('auditApplication.fluentLanguages')} *</label>
                <LanguageSelector
                  selectedLanguages={formData.fluentLanguages}
                  availableLanguages={languages}
                  onAddLanguage={handleAddLanguage}
                  onRemoveLanguage={handleRemoveLanguage}
                  minimumRequired={2}
                />
                <small className={`helper-text ${isShaking ? 'shake' : ''}`}>
                  {t('auditApplication.minimumLanguagesHelper')}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="personalNote">{t('auditApplication.personalNote')}</label>
                <textarea
                  id="personalNote"
                  name="personalNote"
                  value={formData.personalNote}
                  onChange={handleInputChange}
                />
              </div>
              <div className="modal-actions">
                <button type="button" onClick={handleClose} className="cancel-button">
                  {t('common.cancel')}
                </button>
                <button type="submit" disabled={isSubmitting} className="submit-button">
                  {isSubmitting ? t('common.submitting') : t('common.submit')}
                </button>
              </div>
            </form>
          </>
        ) : submissionStatus === 'success' ? (
          <>
            <p>{t('auditApplication.successMessage')}</p>
            <div className="modal-actions">
              <button onClick={handleClose} className="close-button">
                {t('common.close')}
              </button>
            </div>
          </>
        ) : (
          <>
            <p>{t('auditApplication.errorMessage')}</p>
            <div className="modal-actions">
              <button onClick={handleClose} className="close-button">
                {t('common.close')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AuditApplicationModal;