import { useTranslation } from 'react-i18next';

import './css/Pricing.css';

const appSubdomain = process.env.REACT_APP_APP_SUBDOMAIN;
const appSchema = process.env.REACT_APP_APP_SCHEMA || 'https';

const handleTryApp = () => {
  window.open(`${appSchema}://${appSubdomain}/`, '_blank');
};

const handleSubscribe = () => {
  window.open(`${appSchema}://${appSubdomain}/?action=subscribe`, '_blank');
};

const Pricing = () => {

    const { t } = useTranslation();

    return (
      <main className="main-content">
        <h1>{t('pricing.title')}</h1>
        <p className="subtitle">
            {t('pricing.subtitle')}
        </p>

        <div className="pricing-container">
          <div className="pricing-card">
            <h3>{t('pricing.free.title')}</h3>
            <div className="price">
              <span className="amount">$0</span>
              <span className="period">/month USD</span>
            </div>
            <ul className="features-list">
              <li>{t('pricing.free.features.preview')}</li>
              <li>{t('pricing.free.features.languages')}</li>
              <li>{t('pricing.free.features.reader')}</li>
            </ul>
            <button className="select-plan" onClick={handleTryApp}>{t('pricing.free.cta')}</button>
          </div>

          <div className="pricing-card featured">
            <div className="popular-badge">{t('pricing.premium.badge')}</div>
            <h3>{t('pricing.premium.title')}</h3>
            <div className="price">
              <span className="amount">$4.99</span>
              <span className="period">/month USD</span>
            </div>
            <ul className="features-list">
              <li>{t('pricing.premium.features.access')}</li>
              <li>{t('pricing.premium.features.languages')}</li>
              <li>{t('pricing.premium.features.reader')}</li>
            </ul>
            <button className="select-plan" onClick={handleSubscribe}>{t('pricing.premium.cta')}</button>
          </div>
        </div>
      </main>
    );
};

export default Pricing;