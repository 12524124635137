import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useLogger } from '../hooks/useLogger';
import { useTranslation } from 'react-i18next';
import './css/LiteParallelTextHistory.css';

const LiteParallelTextHistory = ({ 
  dyslexicFontEnabled, 
  textAlignment, 
  pageContent,
  pagePronunciation,
  pageFurigana,
  learningLanguage, 
  nativeLanguage, 
  showNativeLanguage,
  showPronunciation,
  showFurigana,
  nativeLanguageDirection,
  learningLanguageDirection,
  overrideHoverIndices = null,
  disableHover = false
}) => {
  const initHoverIndices = overrideHoverIndices || { sentenceIndex: null, wordIndices: { source: [], target: [] } };
  const { t } = useTranslation();
  const logger = useLogger('LiteParallelTextHistory');
  const [hoverIndices, setHoverIndices] = useState(initHoverIndices);
  const [selectedWordDetails, setSelectedWordDetails] = useState(null);
  const [toggledWords, setToggledWords] = useState(new Map());
  const [showPopup, setShowPopup] = useState(false);
  const [isAutoCycling, setIsAutoCycling] = useState(true);
  const cycleTimeoutRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  const hasValidContent = useMemo(() => {
    return !!(
      pageContent &&
      Array.isArray(pageContent.learning_content) &&
      Array.isArray(pageContent.native_content) &&
      pageContent.learning_content.length > 0 &&
      pageContent.native_content.length > 0
    );
  }, [pageContent]);

  const hasValidAssociations = useMemo(() => {
    return !!(
      pageContent?.association?.word_maps?.length > 0 &&
      pageContent?.association?.source_language
    );
  }, [pageContent]);

  const isSourceLearning = useMemo(() => {
    return hasValidAssociations && 
      pageContent.association.source_language === learningLanguage;
  }, [hasValidAssociations, pageContent, learningLanguage]);

  logger.debug(`pageContent: ${JSON.stringify(pageContent)}`);
  logger.debug(`textAlignment: ${textAlignment}`);

  // useEffect(() => {
  //   logger.info(`initHoverIndices: ${JSON.stringify(initHoverIndices)}`);
  // }, [initHoverIndices]);

  useEffect(() => {
    logger.info(`learningLanguage: ${learningLanguage}`);
    logger.info(`nativeLanguage: ${nativeLanguage}`);
    logger.info(`showNativeLanguage: ${showNativeLanguage}`);
    logger.info(`showPronunciation: ${showPronunciation}`);
    logger.info('pageContent:', pageContent);
    logger.info(`hasValidAssociations: ${hasValidAssociations}`);
  }, [learningLanguage, nativeLanguage, showNativeLanguage, showPronunciation, pageContent, pagePronunciation, logger]);

  // Add function to generate random toggled words
  const generateRandomToggledWords = useCallback(() => {
    logger.info(`generateRandomToggledWords called: hasValidContent=${hasValidContent}, showNativeLanguage=${showNativeLanguage}`);
    if (!hasValidContent || !hasValidAssociations) {
      logger.info('Returning empty Map - invalid content or associations');
      return new Map();
    }

    if (showNativeLanguage) {
      logger.info('Returning empty Map - native language shown');
      return new Map();
    }

    const newToggledWords = new Map();
    
    // Helper function to check if a word would display as asterisk or quote
    const wouldDisplayAsSpecialChar = (sentenceIndex, wordIndex, associationMap) => {
      try {
        const targetIndices = associationMap.target_to_source[wordIndex.toString()] || [];
        const associatedSourceIndices = targetIndices[0] !== undefined ? 
          (associationMap.source_to_target[targetIndices[0].toString()] || []) : [];
        
        if (targetIndices.length > 0) {
          if (associatedSourceIndices.length > 1) {
            return associatedSourceIndices[0] !== wordIndex; // Would display as quote
          } else {
            const translation = targetIndices
              .map(index => pageContent.native_content[sentenceIndex].words[index])
              .join(' ');
            return translation === ''; // Would display as asterisk
          }
        }
        return false;
      } catch (error) {
        console.error('Error checking word display:', error);
        return true; // Err on the side of caution
      }
    };
    
    // Process each sentence
    pageContent.learning_content.forEach((sentence, sentenceIndex) => {
      if (!sentence?.words) {
        logger.info(`No words in sentence ${sentenceIndex}`);
        return;
      }

      const associationMap = pageContent.association.word_maps[sentenceIndex];

      // Get valid non-punctuation words that won't display as special chars
      const validWordIndices = sentence.words
        .map((word, index) => ({ word, index }))
        .filter(({ word, index }) => 
          !isPunctuation(word) && 
          !wouldDisplayAsSpecialChar(sentenceIndex, index, associationMap)
        )
        .map(({ index }) => index);

      logger.info(`Found ${validWordIndices.length} valid words to choose from in sentence ${sentenceIndex}`);

      // Select exactly 2 words if possible
      const numWordsToToggle = Math.min(2, validWordIndices.length);
      const shuffledIndices = validWordIndices.sort(() => Math.random() - 0.5);
      const selectedIndices = shuffledIndices.slice(0, numWordsToToggle);

      logger.info(`Selected ${selectedIndices.length} words to toggle in sentence ${sentenceIndex}: ${JSON.stringify(selectedIndices)}`);

      // Add selected words to the map
      selectedIndices.forEach(wordIndex => {
        newToggledWords.set(`${sentenceIndex}-${wordIndex}`, true);
      });
    });

    return newToggledWords;
  }, [hasValidContent, hasValidAssociations, showNativeLanguage, pageContent]);

  // Initialize toggled words on mount and when relevant props change
  useEffect(() => {
    logger.info('Generating random toggled words due to dependency change');
    const newToggledWords = generateRandomToggledWords();
    logger.info(`Setting ${newToggledWords.size} toggled words`);
    setToggledWords(newToggledWords);
  }, [generateRandomToggledWords, showNativeLanguage, pageContent]);

  // Remove the existing useEffect for showNativeLanguage that was clearing toggledWords
  // and replace it with this one that only handles the popup
  useEffect(() => {
    if (!showNativeLanguage) {
      setShowPopup(true);
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [showNativeLanguage]);

  // Add function to generate random hover indices
  const generateRandomHoverIndices = useCallback(() => {
    if (!hasValidContent || !hasValidAssociations) return null;

    const validSentenceIndices = pageContent.learning_content
      .map((_, index) => index)
      .filter(index => pageContent.association.word_maps[index]);

    if (validSentenceIndices.length === 0) return null;

    const randomSentenceIndex = validSentenceIndices[Math.floor(Math.random() * validSentenceIndices.length)];
    const associationMap = pageContent.association.word_maps[randomSentenceIndex];

    // Get valid source words (that have mappings)
    const validSourceIndices = Object.entries(associationMap.source_to_target)
      .filter(([_, targets]) => targets && targets.length > 0)
      .map(([sourceIdx]) => sourceIdx);

    if (validSourceIndices.length === 0) return null;

    // Randomly select a source word
    const randomSourceIdx = validSourceIndices[Math.floor(Math.random() * validSourceIndices.length)];
    const targetIndices = associationMap.source_to_target[randomSourceIdx] || [];

    return {
      sentenceIndex: randomSentenceIndex,
      wordIndices: {
        source: [randomSourceIdx],
        target: targetIndices
      }
    };
  }, [hasValidContent, hasValidAssociations, pageContent]);

  // Add cycling effect
  useEffect(() => {
    const cyclePeriod = 1000; // 2 seconds per highlight

    const doCycle = () => {
      if (isAutoCycling) {
        const newIndices = generateRandomHoverIndices();
        setHoverIndices(newIndices || initHoverIndices);
        cycleTimeoutRef.current = setTimeout(doCycle, cyclePeriod);
      }
    };

    if (isAutoCycling) {
      doCycle();
    }

    return () => {
      if (cycleTimeoutRef.current) {
        clearTimeout(cycleTimeoutRef.current);
      }
    };
  }, [isAutoCycling, generateRandomHoverIndices, initHoverIndices]);

  // Modify handleMouseEnter to clear the debounce timeout
  const handleMouseEnter = (sentenceIndex, wordIndex, isLearning) => {
    if (!hasValidAssociations || disableHover) return;

    setIsAutoCycling(false);
    
    // Clear any pending debounce timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    logger.info(`handleMouseEnter: sentenceIndex: ${sentenceIndex}, wordIndex: ${wordIndex}, isLearning: ${isLearning}`);

    const words = isLearning ? pageContent.learning_content[sentenceIndex].words : pageContent.native_content[sentenceIndex].words;
    if (isPunctuation(words[wordIndex])) return;

    const associationMap = pageContent.association.word_maps[sentenceIndex];
    const isSourceLearning = pageContent.association.source_language === learningLanguage;

    let sourceIndices = new Set();
    let targetIndices = new Set();

    if (isLearning === isSourceLearning) {
      sourceIndices.add(wordIndex.toString());
      const targetIndexes = associationMap.source_to_target[wordIndex.toString()] || [];
      targetIndexes.forEach(idx => targetIndices.add(idx.toString()));
    } else {
      targetIndices.add(wordIndex.toString());
      const sourceIndexes = associationMap.target_to_source[wordIndex.toString()] || [];
      sourceIndexes.forEach(idx => sourceIndices.add(idx.toString()));
    }

    const hoverMap = {
      sentenceIndex: sentenceIndex,
      wordIndices: {
        source: Array.from(sourceIndices),
        target: Array.from(targetIndices)
      }
    }

    logger.info(`hoverMap: ${JSON.stringify(hoverMap)}`);
    setHoverIndices(hoverMap);
  };

  // Modify handleMouseLeave to resume cycling
  const handleMouseLeave = () => {
    if (!hasValidAssociations) return;
    setHoverIndices({ sentenceIndex: null, wordIndices: { source: [], target: [] } });
    
    // Clear any existing debounce timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set new debounce timeout
    debounceTimeoutRef.current = setTimeout(() => {
      setIsAutoCycling(true);
    }, 1000); // 1 second debounce
  };

  const isPunctuation = (word) => {
    return /^[.,!?;:。，！？；：]$/.test(word );
  };

  const renderSentence = (sentence, isLearning, sentenceIndex) => {
    if (!sentence?.words) return null;
    
    const words = sentence.words;
    const direction = isLearning ? learningLanguageDirection : nativeLanguageDirection;
    
    const associationMap = hasValidAssociations ? 
      pageContent.association.word_maps[sentenceIndex] : null;

    const hasPronunciation = !!(
      showPronunciation && 
      isLearning && 
      pagePronunciation?.[sentenceIndex]
    );

    const hasFurigana = !!(
      showFurigana && 
      isLearning && 
      pageFurigana?.[sentenceIndex]
    );

    return (
      <div className={`sentence ${hasPronunciation ? 'has-pronunciation' : ''} ${hasFurigana ? 'has-furigana' : ''}`} dir={direction}>
        {words.map((word, wordIndex) => {
          const isPunc = isPunctuation(word);
          const isHighlighted = !isPunc && 
            hasValidAssociations &&
            hoverIndices.sentenceIndex === sentenceIndex && 
            hoverIndices.wordIndices[isLearning === isSourceLearning ? 'source' : 'target']
              .map(idx => idx.toString())
              .includes(wordIndex.toString());

          const pronunciation = hasPronunciation ? 
            pagePronunciation[sentenceIndex][wordIndex] : null;
          const furigana = hasFurigana ? 
            pageFurigana[sentenceIndex][wordIndex] : null;

          const key = `${sentenceIndex}-${wordIndex}`;
          const isToggled = !showNativeLanguage && toggledWords.has(key);
          
          let displayWord = word;
          if (isToggled && isLearning && hasValidAssociations && associationMap) {
            try {
              const targetIndices = associationMap.target_to_source[wordIndex.toString()] || [];
              const associatedSourceIndices = targetIndices[0] !== undefined ? 
                (associationMap.source_to_target[targetIndices[0].toString()] || []) : [];
              
              if (targetIndices.length > 0) {
                if (associatedSourceIndices.length > 1) {
                  displayWord = associatedSourceIndices[0] === wordIndex ? 
                    targetIndices.map(index => pageContent.native_content[sentenceIndex].words[index]).join(' ') :
                    '"';
                } else {
                  displayWord = targetIndices.map(index => pageContent.native_content[sentenceIndex].words[index]).join(' ');
                  if (displayWord === '') {
                    displayWord = '*';
                  }
                }
              }
            } catch (error) {
              console.error('Error processing word associations:', error);
              displayWord = word;
            }
          }

          return (
            <span
              key={wordIndex}
              className={`word-wrapper ${isHighlighted ? 'highlight-wrapper' : ''} ${isToggled ? 'toggled' : ''}`}
              onMouseEnter={() => !isPunc && handleMouseEnter(sentenceIndex, wordIndex, isLearning)}
              onMouseLeave={handleMouseLeave}
            >
              {isPunc ? (
                <span className="punctuation">{word}</span>
              ) : (
                <ruby className="furigana-wrapper">
                  {displayWord}
                  <rt className="furigana-text">{showFurigana && furigana ? furigana : ''}</rt>
                </ruby>
              )}
              {showPronunciation && pronunciation && !isPunctuation(pronunciation) && displayWord === word && (
                <span className="pronunciation">{pronunciation}</span>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  const renderSentencePair = (learningContent, nativeContent, index) => (
    <div key={index} className={`sentence-pair ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`}>
      <div className="language-one" dir={learningLanguageDirection}>
        {renderSentence(learningContent, true, index)}
      </div>
      {showNativeLanguage && (
        <div className="language-two" dir={nativeLanguageDirection}>
          {renderSentence(nativeContent, false, index)}
        </div>
      )}
    </div>
  );

  if (!hasValidContent) {
    return <p dir={nativeLanguageDirection}>{t('parallelTextHistory.noContent')}</p>;
  }

  return (
    <div className={`parallel-text-history ${textAlignment} ${dyslexicFontEnabled ? 'dyslexic-font' : ''}`} dir={learningLanguageDirection}>
      {pageContent.learning_content.map((sentence, index) => 
        renderSentencePair(
          sentence, 
          pageContent.native_content[index], 
          index
        )
      )}
    </div>
  );
};

export default LiteParallelTextHistory;