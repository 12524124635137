import React from 'react';
import { useTranslation } from 'react-i18next';

import './css/LanguageSelector.css'

const LanguageSelector = ({ 
  selectedLanguages, 
  availableLanguages, 
  onAddLanguage, 
  onRemoveLanguage,
  minimumRequired 
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="selected-languages">
        {selectedLanguages.map(langIso => (
          <div key={langIso} className="selected-language-tag">
            <span>{t(`languages.${langIso}`)}</span>
            <button
              type="button"
              onClick={() => onRemoveLanguage(langIso)}
              className="remove-language-btn"
              disabled={minimumRequired && selectedLanguages.length <= minimumRequired}
            >
              ×
            </button>
          </div>
        ))}
      </div>
      <div className="language-options">
        {availableLanguages
          .filter(lang => !selectedLanguages.includes(lang.iso))
          .map(lang => (
            <div key={lang.iso} className="language-option">
              <span>{t(`languages.${lang.iso}`)}</span>
              <button
                type="button"
                onClick={() => onAddLanguage(lang.iso)}
                className="add-language-btn"
              >
                {t('common.add')} +
              </button>
            </div>
          ))}
      </div>
    </>
  );
};

export default LanguageSelector;