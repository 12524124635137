import React, { useState, useMemo } from 'react';
import { apiRequest } from '../config/api.js';
import './css/LanguageRequestModal.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const LanguageRequestModal = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const languages = [
    { iso: 'ab' }, { iso: 'aa' }, { iso: 'af' }, { iso: 'ak' }, { iso: 'sq' },
    { iso: 'am' }, { iso: 'an' }, { iso: 'hy' }, { iso: 'as' }, { iso: 'av' },
    { iso: 'ae' }, { iso: 'ay' }, { iso: 'az' }, { iso: 'bm' }, { iso: 'ba' },
    { iso: 'eu' }, { iso: 'be' }, { iso: 'bh' }, { iso: 'bi' }, { iso: 'bs' },
    { iso: 'br' }, { iso: 'bg' }, { iso: 'my' }, { iso: 'ca' }, { iso: 'ch' },
    { iso: 'ce' }, { iso: 'ny' }, { iso: 'cv' }, { iso: 'kw' }, { iso: 'co' },
    { iso: 'cr' }, { iso: 'hr' }, { iso: 'cs' }, { iso: 'da' }, { iso: 'dv' },
    { iso: 'dz' }, { iso: 'eo' }, { iso: 'et' }, { iso: 'ee' }, { iso: 'fo' },
    { iso: 'fj' }, { iso: 'fi' }, { iso: 'ff' }, { iso: 'gl' }, { iso: 'ka' },
    { iso: 'gn' }, { iso: 'ht' }, { iso: 'ha' }, { iso: 'hz' }, { iso: 'hu' },
    { iso: 'ia' }, { iso: 'ie' }, { iso: 'ga' }, { iso: 'ig' }, { iso: 'ik' },
    { iso: 'io' }, { iso: 'is' }, { iso: 'iu' }, { iso: 'jv' }, { iso: 'kl' },
    { iso: 'kn' }, { iso: 'kr' }, { iso: 'ks' }, { iso: 'kk' }, { iso: 'km' },
    { iso: 'ki' }, { iso: 'rw' }, { iso: 'ky' }, { iso: 'kv' }, { iso: 'kg' },
    { iso: 'ku' }, { iso: 'kj' }, { iso: 'lb' }, { iso: 'lg' }, { iso: 'li' },
    { iso: 'ln' }, { iso: 'lo' }, { iso: 'lt' }, { iso: 'lu' }, { iso: 'lv' },
    { iso: 'gv' }, { iso: 'mk' }, { iso: 'mg' }, { iso: 'mh' }, { iso: 'mn' },
    { iso: 'na' }, { iso: 'nv' }, { iso: 'nd' }, { iso: 'ne' }, { iso: 'ng' },
    { iso: 'nb' }, { iso: 'nn' }, { iso: 'no' }, { iso: 'ii' }, { iso: 'nr' },
    { iso: 'oc' }, { iso: 'oj' }, { iso: 'cu' }, { iso: 'om' }, { iso: 'or' },
    { iso: 'os' }, { iso: 'pa' }, { iso: 'pi' }, { iso: 'ps' }, { iso: 'qu' },
    { iso: 'rm' }, { iso: 'rn' }, { iso: 'ro' }, { iso: 'sa' }, { iso: 'sc' },
    { iso: 'sd' }, { iso: 'se' }, { iso: 'sm' }, { iso: 'sg' }, { iso: 'sr' },
    { iso: 'gd' }, { iso: 'sn' }, { iso: 'si' }, { iso: 'sk' }, { iso: 'sl' },
    { iso: 'so' }, { iso: 'st' }, { iso: 'su' }, { iso: 'sw' }, { iso: 'ss' },
    { iso: 'tg' }, { iso: 'ti' }, { iso: 'bo' }, { iso: 'tk' }, { iso: 'tl' },
    { iso: 'tn' }, { iso: 'to' }, { iso: 'ts' }, { iso: 'tt' }, { iso: 'tw' },
    { iso: 'ty' }, { iso: 'ug' }, { iso: 've' }, { iso: 'vo' }, { iso: 'wa' },
    { iso: 'cy' }, { iso: 'wo' }, { iso: 'fy' }, { iso: 'xh' }, { iso: 'yi' },
    { iso: 'yo' }, { iso: 'za' }, { iso: 'zu' }
  ];

  // Sort languages by their translated names using useMemo
  const sortedLanguages = useMemo(() => {
    return [...languages].sort((a, b) => {
      const nameA = t(`languages.${a.iso}`);
      const nameB = t(`languages.${b.iso}`);
      return nameA.localeCompare(nameB, i18n.language);
    });
  }, [t, i18n.language]); // Re-sort when translations or language changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await apiRequest('POST', 'REQUEST_LANGUAGE', {}, {}, {
        language_code: selectedLanguage,
        user_locale_code: i18n.language
      });
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error submitting language request:', error);
      setSubmissionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setSubmissionStatus(null);
    setSelectedLanguage('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {submissionStatus === null ? (
          <>
            <h2>{t('languageRequest.title')}</h2>
            <p>{t('languageRequest.description')}</p>
            <form onSubmit={handleSubmit}>
              <select
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
                required
              >
                <option value="">{t('languageRequest.selectPlaceholder')}</option>
                {sortedLanguages.map(lang => (
                  <option key={lang.iso} value={lang.iso}>
                    {t(`languages.${lang.iso}`)}
                  </option>
                ))}
              </select>
              <div className="modal-actions">
                <button type="button" onClick={handleClose} className="cancel-button">
                  {t('common.cancel')}
                </button>
                <button type="submit" disabled={isSubmitting} className="submit-button">
                  {isSubmitting ? t('common.submitting') : t('common.submit')}
                </button>
              </div>
            </form>
          </>
        ) : submissionStatus === 'success' ? (
          <>
            <p>{t('languageRequest.successMessage')}</p>
            <div className="modal-actions">
              <button onClick={handleClose} className="close-button">
                {t('common.close')}
              </button>
            </div>
          </>
        ) : (
          <>
            <p>{t('languageRequest.errorMessage')}</p>
            <div className="modal-actions">
              <button onClick={handleClose} className="close-button">
                {t('common.close')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LanguageRequestModal;